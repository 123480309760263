<template>
    <div class="survey__question" v-if="showBlock">
        <v-card flat class="u-border-bottom">
            <v-card-title>
                <v-flex grow>
                    <h2 class="subtitle-1" style="font-weight: 500"><span
                        style="word-break: break-word; word-wrap: break-word; max-width:75%; display:inline-block;"
                        v-if="getLocalizedValue(question.question)">{{ getLocalizedValue(question.question) }}</span>
                    </h2>
                </v-flex>
                <v-flex shrink class="text-right ml-2" v-if="tooltipText.length > 0">
                    <v-tooltip v-model="showTooltip" top>
                        <template v-slot:activator="{on, attrs}">
                            <v-btn icon @click="showTooltip = !showTooltip">
                                <v-icon color="grey lighten-1">help_outline</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ tooltipText }}</span>
                    </v-tooltip>
                </v-flex>
                <v-flex shrink class="text-right ml-2">
                    <span class="float-right status"
                          :class="{'status--required': questionRequired, 'status--optional': !questionRequired,}">
                        {{
                            questionRequired ? `${this.$t('survey_question.required')}` : `${this.$t('survey_question.optional')}`
                        }}</span>
                </v-flex>
            </v-card-title>
            <v-card-text>
                <component v-show="question.inputType && question.isVisible"
                           :is-required="questionRequired"
                           :placeholder-text="placeholderText"
                           :confirmText="getLocalizedValue(question.confirmText)"
                           :confirmUrl="getLocalizedValue(question.confirmUrl)"
                           :options="question.options" :hintText="getLocalizedValue(question.helpShort, false)"
                           :min="question.min" :max="question.max" :questionId="question.id" :is="fieldTypeComponent"
                           @updateAnswer="onUpdateAnswer" :data="questionDataProp"/>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import EventBus from '../../../eventBus';
import { displayConditionMixin } from '../../../mixins/displayConditionsMixin';
import { mapMutations } from 'vuex';
import GetLocalizedValue from '@/mixins/GetLocalizedValue';

export default {
    name: 'SurveyQuestion',
    props: {
        question: {
            type: Object,
            required: true
        },
        sectionVisible: {
            type: Boolean
        }
    },
    data: () => ({
        showTooltip: false,
        questionRequired: false
    }),
    mixins: [displayConditionMixin, GetLocalizedValue],
    computed: {
        questionDataProp() {
            if (this.question.data) {
                return JSON.parse(this.question.data);
            } else {
                return {};
            }
        },
        tooltipText() {
            return this.getLocalizedValue(this.question.helpDetail, false) || '';
        },
        placeholderText() {
            const text = this.getLocalizedValue(this.question.placeholder, false);
            if (!text) {
                return this.$t('survey_question.empty_placeholder');
            }
            return text;
        },
        fieldTypeComponent() {
            const fieldType = this.question.inputType;
            let component;
            switch (fieldType) {
                case 'STRING':
                    component = 'String';
                    break;
                case 'NUMERIC':
                    component = 'Numeric';
                    break;
                case 'RADIO':
                    component = 'Radio';
                    break;
                case 'CHECKBOX':
                    component = 'Checkbox';
                    break;
                case 'DROPDOWN':
                    component = 'DropDownList';
                    break;
                case 'DATE':
                    component = 'Date';
                    break;
                case 'DATETIME':
                    component = 'DateTime';
                    break;
                case 'BOOLEAN':
                    component = 'TrueFalse';
                    break;
                case 'MEDIA':
                    component = 'Media';
                    break;
                case 'IMO':
                    component = 'IMO';
                    break;
                case 'COSTESTIMATE':
                    component = 'CostEstimate';
                    break;
                case 'DAMAGE':
                    component = 'Damage';
                    break;
                case 'CALLSIGN':
                    component = 'Callsign';
                    break;
                case 'SHIPNAME':
                    component = 'ShipName';
                    break;
                case 'CONFIRM':
                    component = 'Confirm';
                    break;
                default:
                    component = 'String';
            }

            return () => {
                return import(`../FieldTypes/${component}.vue`);
            };
        }
    },
    methods: {
        ...mapMutations('surveys', ['addOrUpdateSurveyAnswer', 'deleteQuestionFromSurveyAnswers']),
        onUpdateAnswer(answer) {
            const valueLengthCheck = answer.toString().length > 0;
            if (valueLengthCheck) {
                this.addOrUpdateSurveyAnswer({
                    id: this.question.id,
                    value: answer
                });
            }
            if (answer.toString().length === 0) {
                this.deleteQuestionFromSurveyAnswers(this.question.id);
            }
        },
        setQuestionRequired() {
            if (this.sectionVisible && this.question.isRequired) {
                this.questionRequired = true;
            } else {
                this.questionRequired = false;
            }
        },
        setQuestionVisible() {
            this.showBlock = !this.question.hasConditions;
            if (!this.question.isVisible) {
                this.showBlock = false;
            }
        }
    },
    created() {
        this.setQuestionRequired();
        this.setQuestionVisible();
        EventBus.$on('stepChange', () => {
            this.showTooltip = false;
        });
    }
};
</script>

<style lang="scss">
.v-input--checkbox .v-input__slot {
    margin-bottom: 0 !important;
}

.v-input--selection-controls {
    margin-top: 7px !important
}

.survey__question .status {
    position: relative;
    top: 0px;
    border: 1px solid #888;
    color: #888;
    border-radius: 5px;
    font-size: 10px;
    padding: 3px 5px;
    line-height: 11px;

    &--required {
        border-color: #000000;
        color: #000;
    }
}
</style>
