<template>
    <v-card flat class="survey__page">
        <v-card-title style="word-break: break-word; word-wrap: break-word;">{{ getLocalizedValue(page.title) }}
        </v-card-title>
        <v-card-text class="px-0">
            <survey-section v-for="section in page.children" :key="section.id" :section="section"/>
            <div class="text-center" v-if="!hasVisibleSections">{{ $t('survey.page.no_sections_visible') }}</div>
        </v-card-text>
    </v-card>
</template>

<script>
import SurveySection from './SurveySection';
import GetLocalizedValue from '@/mixins/GetLocalizedValue';
import { mapState } from "vuex";

export default {
    name: 'SurveyPage',
    props: {
        page: {
            type: Object,
            required: true
        }
    },
    mixins: [
        GetLocalizedValue
    ],
    components: {
        SurveySection
    },
    computed: {
        hasVisibleSections() {
            if (this.page && this.page.children) {
                return this.page.children.some(section => {
                    if (section.hasConditions) {
                        const conditions = section.displayConditions ? section.displayConditions[0] : null;

                        const conditionOperator = conditions ? conditions.operator : null;
                        const conditionValue = conditions.value.toString();
                        const conditionInputType = conditions.conditionQuestionInputType;
                        const conditionQuestionId = conditions.conditionQuestionId;
                        let answerSplit;
                        if (this.currentSurveyAnswers.length > 0 && conditions) {
                            const currentAnswer = this.currentSurveyAnswers.find((item) => {
                                if (item[conditions.conditionQuestionId]) {
                                    return item[conditions.conditionQuestionId];
                                }
                            });
                            if (currentAnswer) {
                                let currentAnswerValue = currentAnswer[conditionQuestionId];
                                if (conditionOperator === 'IS') {
                                    if (conditionInputType === 'MULTIPLE' || conditionInputType === 'CHECKBOX' || conditionInputType === 'RADIO' || conditionInputType === 'DROPDOWN' || conditionInputType === 'COSTESTIMATE' || conditionInputType === 'DAMAGE') {
                                        answerSplit = currentAnswer[conditionQuestionId].toString().split(',');
                                        return !!(currentAnswer && answerSplit.includes(conditionValue));
                                    } else {
                                        return !!(currentAnswer && conditionValue === currentAnswerValue.toString());
                                    }
                                }
                                if (conditionOperator === 'ISNOT') {
                                    if (conditionInputType === 'MULTIPLE' || conditionInputType === 'CHECKBOX' || conditionInputType === 'RADIO' || conditionInputType === 'DROPDOWN' || conditionInputType === 'COSTESTIMATE' || conditionInputType === 'DAMAGE') {
                                        answerSplit = currentAnswer[conditionQuestionId].toString().split(',');
                                        return !(currentAnswer && answerSplit.includes(conditionValue));
                                    } else {
                                        return !(currentAnswer && conditionValue !== currentAnswerValue.toString());
                                    }
                                }
                                if (conditionOperator === 'GREATERTHAN') {
                                    return parseInt(currentAnswerValue) > parseInt(conditionValue);
                                }
                                if (conditionOperator === 'LESSTHAN') {
                                    return parseInt(currentAnswerValue) < parseInt(conditionValue);
                                }
                            } else {
                                return false;
                            }
                        }
                        return false;
                    } else {
                        return true;
                    }
                })
            }
            return true;
        },
        ...mapState('surveys', ['currentSurveyAnswers'])

    }
};
</script>
