<template>
    <div class="survey__section" v-if="showBlock">
        <v-expansion-panels flat accordion v-model="panel" focusable class="elevation-0">
            <v-expansion-panel style="background-color:#f1f1f1;">
                <v-expansion-panel-header><div style="word-break: break-word; word-wrap: break-word;">{{getLocalizedValue(section.title)}}</div></v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4">
                    <survey-question v-for="question in section.children" :key="question.id" :section-visible="showBlock" :question="question"/>
                </v-expansion-panel-content>
                <v-btn color="#D8D8D8" style="height:64px" block depressed @click="togglePanel" v-if="panel === 0">
                    <div class="d-flex flex-grow-1">
                    <div style="word-break: break-word; word-wrap: break-word;">
                        {{ getLocalizedValue(section.title) }}
                    </div>
                        <div style="flex:1; display:flex; justify-content: flex-end"><v-icon :size="25" style="padding-right:23px;" right>keyboard_arrow_up</v-icon></div>
                    </div>
                </v-btn>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import {displayConditionMixin} from '../../../mixins/displayConditionsMixin';
import SurveyQuestion from './SurveyQuestion';
import GetLocalizedValue from '@/mixins/GetLocalizedValue';

export default {
    name: 'SurveySection',
    props: {
        section: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        panel: 0,
        closeSectionText: 'Close'
    }),
    mixins: [displayConditionMixin, GetLocalizedValue],
    components: {
        SurveyQuestion
    },
    methods: {
        togglePanel() {
            if (this.panel === 0) {
                this.panel = 1;
                this.closeSectionText = 'Open';
            } else {
                this.panel = 0;
                this.closeSectionText = 'Close';
            }
        }
    },
    created() {
        this.showBlock = !this.section.hasConditions;
        this.checkDisplayConditions();
    }
};
</script>

<style>
    .v-expansion-panel::before {
        box-shadow: none !important;
    }
</style>
