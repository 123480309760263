<template>
    <v-dialog
        v-model="dialog"
        max-width="290"
    >
        <v-card>
            <v-card-title class="headline text-center d-block">{{$t('modal.error_in_form.title')}}</v-card-title>

            <v-card-text>
                <div class="text-center">
                    <v-icon size="70" class="mb-2" color="primary">error</v-icon>
                    <p>{{$t("modal.error_in_form.description")}}</p>
                    <v-btn color="primary" min-width="150" depressed rounded @click="onCloseModal">
                        {{ $t("no") }}
                    </v-btn>
                    <v-btn text min-width="150" @click="onConfirm">
                        {{ $t("yes") }}
                    </v-btn>
                </div>
            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ModalErrorInForm',
    data: () => ({
        dialog: true
    }),
    methods: {
        onCloseModal() {
            this.$emit('closeModalIsError');
        },
        onConfirm() {
            this.$emit('confirmProceedToNextPage');
            this.$emit('closeModalIsError');
        }
    }
};
</script>
