import {mapState} from 'vuex';

export const displayConditionMixin = {
    data: () => ({
        showBlock: null,
        conditions: null
    }),
    computed: {
        ...mapState('surveys', ['currentSurveyAnswers'])
    },
    watch: {
        currentSurveyAnswers: function() {
            this.checkDisplayConditions();
        }
    },
    methods: {
        displayBlock() {
            this.showBlock = true;
            this.$nextTick(() => {
                this.showBlock = true;
            });
        },
        hideBlock() {
            this.showBlock = false;
            this.$nextTick(() => {
                this.showBlock = false;
            });
        },
        checkDisplayConditions() {
            if (this.currentSurveyAnswers.length > 0 && this.conditions) {
                const conditionOperator = this.conditions ? this.conditions.operator : null;
                const conditionValue = this.conditions.value.toString();
                const conditionInputType = this.conditions.conditionQuestionInputType;
                const conditionQuestionId = this.conditions.conditionQuestionId;
                let answerSplit;
                let currentAnswer = '';
                let currentAnswerValue = null;

                currentAnswer = this.currentSurveyAnswers.find((item) => {
                    if (item[this.conditions.conditionQuestionId]) {
                        return item[this.conditions.conditionQuestionId];
                    } else {
                        return '';
                    }
                });

                if (currentAnswer) {
                    currentAnswerValue = currentAnswer[conditionQuestionId];
                    if (conditionOperator === 'IS') {
                        if (conditionInputType === 'MULTIPLE' || conditionInputType === 'CHECKBOX' || conditionInputType === 'RADIO' || conditionInputType === 'DROPDOWN' || conditionInputType === 'COSTESTIMATE' || conditionInputType === 'DAMAGE') {
                            answerSplit = currentAnswer[conditionQuestionId].toString().split(',');
                            if (currentAnswer && answerSplit.includes(conditionValue)) {
                                this.displayBlock();
                            } else {
                                this.hideBlock();
                            }
                        } else {
                            if (currentAnswer && conditionValue === currentAnswerValue.toString()) {
                                this.displayBlock();
                            } else {
                                this.hideBlock();
                            }
                        }
                    }
                    if (conditionOperator === 'ISNOT') {
                        if (conditionInputType === 'MULTIPLE' || conditionInputType === 'CHECKBOX' || conditionInputType === 'RADIO' || conditionInputType === 'DROPDOWN' || conditionInputType === 'COSTESTIMATE' || conditionInputType === 'DAMAGE') {
                            answerSplit = currentAnswer[conditionQuestionId].toString().split(',');
                            if (currentAnswer && answerSplit.includes(conditionValue)) {
                                this.hideBlock();
                            } else {
                                this.displayBlock();
                            }
                        } else {
                            if (currentAnswer && conditionValue !== currentAnswerValue.toString()) {
                                this.hideBlock();
                            } else {
                                this.displayBlock();
                            }
                        }
                    }
                    if (conditionOperator === 'GREATERTHAN') {
                        if (parseInt(currentAnswerValue) > parseInt(conditionValue)) {
                            this.displayBlock();
                        } else {
                            this.hideBlock();
                        }
                    }
                    if (conditionOperator === 'LESSTHAN') {
                        if (parseInt(currentAnswerValue) < parseInt(conditionValue)) {
                            this.displayBlock();
                        } else {
                            this.hideBlock();
                        }
                    }
                } else {
                    this.hideBlock();
                }
            }
        }
    },
    created() {
        if (this.section) {
            this.conditions = this.section.displayConditions ? this.section.displayConditions[0] : null;
        }
        if (this.question) {
            this.conditions = this.question.displayConditions ? this.question.displayConditions[0] : null;
        }
        if (this.conditions) {
            this.checkDisplayConditions();
        }
    }
};
