<template>
    <v-card flat class="survey-details pad-survey-details-buttons">
        <v-card-text class="pa-0 px-0 ">

            <v-window touchless v-model="step">
                <v-window-item v-for="(page, index) in pagesAvailableForRole.survey.children" :key="page.id"
                               class="u-height-auto"
                               :value="index">
                    <v-form :ref="`form-${index}`" v-model="valid[index]">
                        <survey-page :page="page" />
                    </v-form>
                </v-window-item>
            </v-window>
            <v-alert type="error" tile icon="error_outline" v-show="isError" id="errorContainer" ref="errorContainer">
                {{ $t("survey.problem_submitting") }}
                <br />
                <template v-if="surveyErrors.length === 0">
                    {{ errorText }}
                </template>
                <i18n path="survey.error_message_for_question" tag="div"
                      v-for="(error, index) in surveyErrors" :key="index">
                    <template v-slot:question>{{ getLocalizedValue(error.question) }}</template>
                    <template v-slot:error>{{ $t(error.error) }}</template>
                    <template v-slot:value>{{ error.value ? error.value.toString() : "" }}</template>
                </i18n>
            </v-alert>

            <div class="survey-details__buttons pb-3 px-3">
                <v-container v-if="pagesAvailableForRole.survey.children">
                    <v-row>
                        <v-flex>
                            <v-btn @click="step--" color="secondary" outlined rounded depressed
                                   v-if="step !== 0">
                                {{ $t("basic.pagination.prev") }}

                            </v-btn>
                        </v-flex>
                        <v-flex class="justify-end d-flex">
                            <v-btn rounded depressed outlined @click="validateStep"
                                   color="primary"
                                   v-if="step !== pagesAvailableForRole.survey.children.length - 1">
                                {{ $t("basic.pagination.next") }}
                            </v-btn>
                            <v-btn ref="submit" rounded depressed outlined @click="onSubmit"
                                   :loading="isLoading"
                                   color="primary" v-if="step === pagesAvailableForRole.survey.children.length - 1">
                                {{ $t("survey.submit") }}
                            </v-btn>
                        </v-flex>
                    </v-row>
                </v-container>

                <div class="justify-end d-flex" v-if="!pagesAvailableForRole.survey.children || isError">
                    <v-btn ref="submit2" v-if="!isError" :loading="isLoading" color="primary"
                           @click="onSubmit" rounded
                           depressed outlined>
                        {{ $t("survey.submit") }}
                    </v-btn>
                    <v-btn ref="submit3" v-if="isError && step !== pagesAvailableForRole.survey.children.length - 1"
                           small :loading="isLoading" color="primary" @click="onSubmit" rounded
                           depressed outlined>
                        {{ $t("survey.resubmit") }}
                    </v-btn>
                </div>

            </div>

            <modal-error-uploading-images v-if="errorUploadingImages"
                                          :error-message="errorMessageUploadImages"
                                          @closeModalIsErrorImages="errorUploadingImages = false" />
            <modal-error-in-form @confirmProceedToNextPage="step++" @closeModalIsError="showErrorInForm = false"
                                 v-if="showErrorInForm" />
            <modal-is-uploading-images @allFilesUploaded="onAllFilesUploaded"
                                       @allFilesUploadedFailed="onAllFilesUploadedFailed"
                                       v-if="isUploadingFiles" />
            <modal-survey-created v-if="showModalSurveyCreated" />

        </v-card-text>
    </v-card>
</template>

<script>
import EventBus from '../../../eventBus';
import {mapMutations, mapState} from 'vuex';
import SurveyPage from './SurveyPage';
import ModalSurveyCreated from '../Modals/ModalSurveyCreated';
import {deletePendingSurveyByID} from '../../../helpers/indexedDB';
import ModalIsUploadingImages from '../Modals/ModalIsUploadingImages';
import ModalErrorInForm from '../Modals/ModalErrorInForm';
import * as NoSleep from '@hacksawstudios/nosleep.js/dist/NoSleep.min';
import ModalErrorUploadingImages from '../Modals/ModalErrorUploadingImages';
import GetLocalizedValue from '@/mixins/GetLocalizedValue';

export default {
    name: 'SurveyDetail',
    props: {
        surveyInstanceId: {required: false},
        surveyInstanceVersion: {required: false},
    },
    mixins: [
        GetLocalizedValue
    ],
    data: () => ({
        valid: [],
        step: 0,
        isLoading: false,
        isError: false,
        errorText: '',
        files: [],
        surveyErrors: [],
        isUploadingFiles: false,
        showErrorInForm: false,
        errorUploadingImages: false,
        errorMessageUploadImages: '',
        wakeLockEnabled: false,
        noSleep: new NoSleep()
    }),
    computed: {
        ...mapState('surveys', ['currentSurvey', 'tempSurveyId']),
        ...mapState('modals', ['showModalSurveyCreated']),
        ...mapState('users', ['currentUser']),
        pagesAvailableForRole() {
            return this.$store.getters['surveys/getCurrentSurveyWithRoles'];
        }
    },
    watch: {
        step: function() {
            this.scrollToTop();
            EventBus.$emit('stepChange');
        }
    },
    components: {ModalErrorUploadingImages, ModalErrorInForm, ModalIsUploadingImages, ModalSurveyCreated, SurveyPage},
    methods: {
        ...mapMutations('surveys', ['savePendingSurvey', 'updateFilesToQuestion', 'clearSurveyAnswers', 'clearSurveyFiles']),
        onAllFilesUploaded() {
            deletePendingSurveyByID(this.tempSurveyId);
            this.isUploadingFiles = false;
            this.showCompletedModal();
        },
        onAllFilesUploadedFailed(message) {
            this.isUploadingFiles = false;
            this.errorMessageUploadImages = message;
            this.showErrorImagesModal();
        },
        showErrorImagesModal() {
            this.savePendingSurvey();
            this.errorUploadingImages = true;
        },
        showCompletedModal() {
            this.$store.dispatch('modals/toggleModal', 'showModalSurveyCreated');
        },
        scrollToTop() {
            window.scroll(0, 0);
        },
        validateStep() {
            if (this.$refs[`form-${this.step}`][0].validate()) {
                this.step++;
            } else {
                this.showErrorInForm = true;
            }
        },
        toggleWakeLock() {
            if (!this.wakeLockEnabled) {
                this.noSleep.enable(); // keep the screen on!
                this.wakeLockEnabled = true;
            } else {
                this.noSleep.disable(); // let the screen turn off.
                this.wakeLockEnabled = false;
            }
        },
        onSubmit() {
            this.isError = false;
            this.surveyErrors = [];
            this.isLoading = true;
            let payload = null;
            if (this.surveyInstanceId) {
                payload = {
                    instanceId: this.surveyInstanceId,
                    instanceVersion: this.surveyInstanceVersion
                };
            }

            this.$store.dispatch('surveys/submitSurvey', payload).then((response) => {
                this.isLoading = false;
                const allUploads = response.surveySubmit.uploads;
                const errors = response.surveySubmit.errors;
                const instanceId = response.surveySubmit.message;
                if (errors) {
                    this.isError = true;
                    this.isLoading = false;
                    this.errorText = this.$t('survey.error_missing_required');
                    this.surveyErrors = [...errors];
                    this.$nextTick(() => {
                        // console.log({errorContainer:this.$refs.errorContainer, active:this.$refs.errorContainer.isActive});
                        this.$vuetify.goTo(this.$refs.errorContainer).then((msg) => {
                            console.log('scrolled', msg);
                        });
                    });
                } else {
                    this.isError = false;
                }

                if (allUploads) {
                    allUploads.forEach((upload, index) => {
                        const answerId = upload.answerId;
                        const filePath = upload.path;
                        const fileType = upload.fileType;
                        const questionId = upload.questionId;
                        this.updateFilesToQuestion({
                            instance: instanceId,
                            uploads: allUploads,
                            answerId: answerId,
                            path: filePath,
                            questionId: questionId,
                            fileType: fileType,
                            optionId: upload.optionId
                        });

                        if (index === allUploads.length - 1) {
                            this.isUploadingFiles = true;
                            this.toggleWakeLock();
                        }
                    });
                }
                if (!allUploads && !this.isError) {
                    this.showCompletedModal();
                }
            }).catch((err) => {
                this.errorText = err;
                this.isError = true;
                this.isLoading = false;
            });
        }
    },
    beforeDestroy() {
        this.$store.dispatch('modals/closeModal');
        this.noSleep.disable();
        this.wakeLockEnabled = false;
    }
};
</script>

<style scoped>
.u-height-auto,
.v-carousel {
    height: auto !important;
}

.pad-survey-details-buttons {
    padding-bottom: 15vh;
}

.survey-details__buttons {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #ffffff;
    z-index: 8;
}
</style>
